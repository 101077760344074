import React from 'react';
import { string } from 'prop-types';
import {
  Box,
  AspectRatio,
  Text,
  Button,
  Center,
  Link,
  Heading,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import playMusic from '@components/Common/FloatingMusic/utils/playMusic';

import {
  THE_BRIDE,
  YOUTUBE_EMBED,
  YOUTUBE_LINK,
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_FULLDATE,
} from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';

import WithAnimation from '@components/Common/WithAnimation';
import { ENABLE_VIDEO_PREWEDDING } from '@/constants/feature-flags';
import { IMG_YOUTUBE_THUMBNAIL, ASSETS_FLOWER_LIVE_STREAMING } from '@/constants/assets';
import txt from './locales';

/**
 * @function YoutubeLiveSection
 * Render Live Streaming component
 * @returns {JSX.Element}
 */
function YoutubeLiveSection({ lang, ...rest }) {
  const { isOpen, onToggle } = useDisclosure();

  const handlePlayYoutube = () => {
    playMusic(true);
    onToggle();
  };

  return (
    <Box position="relative" textAlign="center" {...rest}>
      {/* LIVE STREAMING SECTION */}
      <Box width="100%">
        <WithAnimation>
          <Heading
            fontWeight="normal"
            size="xl"
            textAlign="center"
            margin="0"
            color="secondaryColorText"
            fontFamily="CustomFont"
            marginBottom="24px"
          >
            {ENABLE_VIDEO_PREWEDDING ? 'prewed video' : 'live streaming'}
          </Heading>
        </WithAnimation>
        {false && (
          <WithAnimation>
            <Text
              fontSize="md"
              textAlign="center"
              color="mainColorText"
              marginBottom="16px"
              dangerouslySetInnerHTML={{
                __html: `${WEDDING_AKAD_FULLDATE} | ${WEDDING_AKAD_TIME}`,
              }}
            />
          </WithAnimation>
        )}
        {/* Live stream section */}
        <WithAnimation right>
          <Box pos="relative">
            <AspectRatio
              maxW="560px"
              ratio={16 / 9}
              boxShadow="xl"
              style={{ display: isOpen ? '' : 'none' }}
            >
              <iframe
                id="ytplayer"
                title={`Live wedding of ${THE_BRIDE}`}
                src={YOUTUBE_EMBED}
                allowFullScreen
                style={{ borderRadius: '8px' }}
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
            </AspectRatio>
            {isOpen ? null : (
              <WithAnimation>
                <AspectRatio maxW="560px" ratio={16 / 9} boxShadow="xl">
                  <Image
                    src={IMG_YOUTUBE_THUMBNAIL}
                    onClick={handlePlayYoutube}
                    borderRadius="8px"
                  />
                </AspectRatio>
              </WithAnimation>
            )}
            <Image
              src={ASSETS_FLOWER_LIVE_STREAMING}
              height="140px"
              pos="absolute"
              top="-16px"
              right="-16px"
            />
            <Image
              src={ASSETS_FLOWER_LIVE_STREAMING}
              height="140px"
              pos="absolute"
              bottom="-24px"
              left="-16px"
              transform="scaleX(-1)"
            />
          </Box>
        </WithAnimation>
        <WithAnimation right>
          <Text
            textAlign="center"
            fontSize="md"
            color="mainColorText"
            margin="16px 0"
            padding="0 12px 0 12px"
          >
            {txt.problem[lang]}
          </Text>
        </WithAnimation>
        <WithAnimation left>
          <Center>
            <Link href={YOUTUBE_LINK} target="_blank" _hover={{ textDecoration: 'none' }}>
              <Button
                size="xs"
                fontWeight="normal"
                bgColor="bgAlternative"
                marginBottom="16px"
                {...BUTTON_PROPS}
                {...BUTTON_PADDING_PROPS}
                fontSize="md"
              >
                {txt.open[lang]}
              </Button>
            </Link>
          </Center>
        </WithAnimation>
      </Box>
    </Box>
  );
}

YoutubeLiveSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(YoutubeLiveSection);
